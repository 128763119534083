








































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

import Accordion from '@/components/Accordion/index.vue';

import TabPlannerAccordion from '../TabPlannerAccordion/TabPlannerAccordion.vue';

@Component({
  name: 'PlannerTree',
  components: {
    Accordion,
    TabPlannerAccordion,
  },
})
export default class PlannerAccordion extends Vue {
  @Prop() week!: Array<Record<string, any>>;
  @Prop({ default: 1 }) padding!: number;
  @Prop() index!: number;
  @Prop() accordionIndex!: number;

  setHash() {
    return Math.floor(Math.random() * (1000 - 0)) + 0;
  }

  verifyFiles(discipline: Record<string, any>) {
    // || (discipline.exercices && discipline.exercices.length)
    return (discipline?.videos && discipline?.videos.length) || (discipline.archives && discipline.archives.length);
  }
}
