













































































import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import { format, add } from 'date-fns';
import { pt } from 'date-fns/locale';

const ID_DISCIPLINE_ENGLISH = 3;
const ID_DISCIPLINE_SPANISH = 13;

@Component({})
export default class SummaryPlanner extends Vue {
  @Prop() infoWeekPlanner!: Record<string, any>;

  get period() {
    return `${this.formatDate(this.infoWeekPlanner?.start)} a ${this.formatDate(this.infoWeekPlanner?.end)}`;
  }

  get quantityDisciplinesExclude() {
    return this.modifierNumber(this.infoWeekPlanner?.disciplinesExclude?.length);
  }

  get quantityTopicsExclude() {
    return this.modifierNumber(this.infoWeekPlanner?.topicsExclude?.length);
  }

  get series() {
    if (this.infoWeekPlanner.series.length >= 3) return 'Ensino Médio Completo';

    if (this.infoWeekPlanner.series[0] >= 4) return '9º ano';

    return `${this.infoWeekPlanner.series[0]}º ano`;
  }

  get language() {
    if (this.infoWeekPlanner?.disciplinesExclude?.includes(ID_DISCIPLINE_ENGLISH)) return 'Espanhol';

    if (this.infoWeekPlanner?.disciplinesExclude?.includes(ID_DISCIPLINE_SPANISH)) return 'Inglês';

    return 'Quero fazer as duas';
  }

  formatDate(date: string) {
    const newDate = add(new Date(date), { hours: 3 });
    const dayOfMonth = format(newDate, 'dd/LL/yyyy', {
      locale: pt,
    });

    return dayOfMonth;
  }

  modifierNumber(value: number) {
    return value < 10 ? `0${value}` : String(value);
  }

  setTextBoolean(value: boolean) {
    return value ? 'Sim' : 'Não';
  }
}
