











































// Dependencies
import {
  Component, Prop, Mixins, Watch,
} from 'vue-property-decorator';
// Components

import Tab from '@/components/Subject/Tab/index.vue';
import MaterialsTab from '@/components/TabsAccordion/MaterialTab/index.vue';
import VideosTab from '@/components/TabsAccordion/VideosTab/index.vue';

// Services
import HistoricService from '@/services/Historic/historicService';

// Mixins
import accordionTab from '@/mixins/accordionTab';
import generalBooks from '@/mixins/generalBooks';

import { LIST_PERMISSION } from '@/constant/ListPermission';

import { TypeMaterial } from '@/services/Historic/historicInterface';

@Component({
  mixins: [accordionTab],
  components: {
    Tab,
    MaterialsTab,
    VideosTab,
  },
})
export default class TabPlannerAccordion extends Mixins(generalBooks) {
  @Prop({ required: true }) discipline!: Record<string, any>;
  @Prop({ default: '0rem 1rem' }) padding!: string;
  @Prop({ required: true }) index!: number;

  private allChecked: Record<string, any> = {
    videos: [],
    materials: [],
    exercices: [],
  }

  private ids: Record<string, any> = {
    videos: [],
    archives: [],
    exercices: [],
  }

  private HistoricService = new HistoricService();

  get isPermission() {
    return this.can(LIST_PERMISSION.PLANNER);
  }

  @Watch('discipline', {
    immediate: true,
  })
  async loadData() {
    this.getIds(this.discipline, 'videos', 'idVideo');
    this.getIds(this.discipline, 'archives', 'idArchive');

    await this.getAllFilesChecked(this.ids.videos, 'video', 'videos');
    await this.getAllFilesChecked(this.ids.archives, 'materiais', 'materials');
  }

  getIds(data: Record<string, any>, param: string, idType: string) {
    if (!data?.topics || data?.topics.length <= 0) {
      if (data[param] && data[param].length > 0) {
        const ids = data[param].map((item: Record<string, any>) => item[idType]);
        this.ids[param] = [...this.ids[param], ...ids];
      }
    } else if (data?.topics && data?.topics.length) {
      data.topics.forEach((topic: Record<string, any>) => {
        this.getIds(topic, param, idType);
      });
    }
  }

  async getAllFilesChecked(ids: Array<number>, historicParam: TypeMaterial, allChechedParam: string) {
    if (ids && ids.length > 99) {
      const response = await this.getHistoric(historicParam, ids.slice(0, 100));

      this.allChecked[allChechedParam].push(...response);
      await this.getAllFilesChecked(ids.slice(100), historicParam, allChechedParam);
    } else if (ids && ids.length) {
      const response = await this.getHistoric(historicParam, ids);

      this.allChecked[allChechedParam].push(...response);
    }
  }

  async getHistoric(type: TypeMaterial, listIds: Array<number>) {
    try {
      const response = await this.HistoricService.getHistoric({ type, listIds });

      return response || [];
    } catch (error) {
      return [];
    }
  }

  quantityFiles(discipline: Record<string, any>) {
    const videos = discipline?.videos && discipline?.videos.length ? discipline?.videos : [];
    const archives = discipline?.archives && discipline?.archives.length ? discipline?.archives : [];
    const exercices = discipline?.exercices && discipline?.exercices.length ? discipline?.exercices : [];

    return {
      videos,
      exercices,
      archives,
    };
  }

  haveFile(discipline: Record<string, any>, type: string) {
    return discipline[type] && discipline[type].length;
  }

  setData(item: Record<string, any>, type: string) {
    if (type === 'video') {
      return {
        to: '/videos', from: `planner/${this.index}`, file: item, id: item.idVideo,
      };
    }

    if (type === 'material') {
      return {
        to: '/materiais',
        from: `planner/${this.index}`,
        file: item,
        id: item.idArchive,
      };
    }

    return '';
  }

  updateAllChecked(event: number) {
    const newAllChecked: Record<string, any> = this.allChecked;
    newAllChecked.materials.push({
      idobject: event,
      checked: true,
    });

    this.allChecked = newAllChecked;
  }
}
