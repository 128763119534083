import PdfService from '@/services/Pdf/pdfService';

const pdfService = new PdfService();

async function downloadFiles(i: number, downloadFile: any, token: string) {
  if (i >= downloadFile.length) return;

  const files = downloadFile;

  const a = document.createElement('a') as HTMLAnchorElement;

  const { slugfix } = files[i];

  const request = await pdfService.getPdf(slugfix, token);

  const file = new Blob([request], { type: 'application/pdf' });

  a.href = window.URL.createObjectURL(file);

  a.download = files[i].title;

  (document.body || document.documentElement).appendChild(a);
  a.click();
  // @ts-ignore
  a.parentNode.removeChild(a);
  setTimeout(() => {
    URL.revokeObjectURL(a.href);
    downloadFiles(i + 1, downloadFile, token);
  }, 500);
}

export default { downloadFiles };
