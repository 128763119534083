import { Vue, Component } from 'vue-property-decorator';

@Component
export default class AccordionTab extends Vue {
  private showDownloadTab = false;

  toggleDownloadTab(value: boolean) {
    this.showDownloadTab = value;
  }
}
