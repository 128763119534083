








































import {
  Component, Prop, Watch, Mixins,
} from 'vue-property-decorator';
import { uniqBy } from 'lodash';
import moment from 'moment';

import SubjectList from '@/components/Subject/SubjectList/index.vue';
import Checkbox from '@/components/Input/Checkbox/index.vue';
import RegroupType from '@/components/RegroupType/index.vue';
import InfoCountExercises from '../InfoCountExercises/index.vue';

import { hasPermissionMaterials } from '@/share/Util/Permissions/materials';

import { LIST_BOOK_ID } from '@/constant/ListBookId';
import { LIST_PERMISSION } from '@/constant/ListPermission';

import permission from '@/mixins/permission';
import listAccordion from '@/mixins/listAccordion';

import { ExercisesMaterialsCount } from '@/globalInterfaces/Book';

interface InterfaceMaterials {
  slugfix?: string;
  checked?: boolean;
}

const ROUTE_BOOK = 'BooksView';
const nameTheory = 'TEORIA';
const TYPE = 'archives';
const ONE_MINUTE = 60;
const THREE_MINUTES = 3 * ONE_MINUTE;
const TOTAL_QUESTIONS_THIRTY_MINUTE = 10;

interface MaterialCategoryStore {
  categories: Array<Category>;
  context: string;
}

interface Category {
  idCategory: number;
  checked: boolean;
}

@Component({
  mixins: [listAccordion],
  components: {
    SubjectList,
    Checkbox,
    RegroupType,
    InfoCountExercises,
  },
})
export default class MaterialList extends Mixins(permission) {
  @Prop({ default: false }) showDownloadTab!: boolean;
  @Prop({ default: false }) showLesson!: boolean;
  @Prop({ default: false }) isPermission!: boolean;
  @Prop({ default: false }) availablePermissionBook!: boolean;
  @Prop({ default: false }) checkedAllMaterial!: boolean;
  @Prop({ default: null }) idBook!: null | number;
  @Prop({ default: 0 }) count!: number;
  @Prop() allChecked!: Array<Record<string, any>>;
  @Prop() materials!: Record<string, any>;
  @Prop() topic!: Record<string, any>;
  @Prop() categoryInformation!: {[key: number]: ExercisesMaterialsCount};
  @Prop() checked!: Function;
  @Prop() setData!: Function;

  private downloadFile: Array<InterfaceMaterials> = [];
  private newMaterials: Array<Record<string, any>> = [];

  created() {
    this.mappersMaterials();
  }

  get isBook() {
    return this.$route.name === ROUTE_BOOK;
  }

  get contentCategory() {
    return this.categoryInformation[this.materials.group];
  }

  get totalQuestions() {
    return this.newMaterials.reduce((acc, material: Record<string, any>) => acc + material?.questionCount, 0);
  }

  get showInfoQuestions() {
    if (!this.isBook) {
      if (!this.totalQuestions) return '';

      const timeAdditional = this.newMaterials.reduce((acc, material: Record<string, any>) => {
        const totalTimeMaterial = this.verifyNameTheory(material?.title) ? TOTAL_QUESTIONS_THIRTY_MINUTE : 0;

        return acc + totalTimeMaterial;
      }, 0);

      const time = this.setTime((this.totalQuestions + timeAdditional) * THREE_MINUTES, '|');

      return `${this.totalQuestions} ${this.totalQuestions > 1 ? 'questões' : 'questão'} ${time}`;
    }

    if (!this.contentCategory
      || (!this.contentCategory.totalQuestions && !this.contentCategory.totalTime)
    ) return '';

    if (!this.contentCategory.totalQuestions) return this.setTime(this.contentCategory.totalTime);

    return `${this.contentCategory.totalQuestions} ${this.contentCategory.totalQuestions > 1 ? 'questões' : 'questão'} ${this.setTime(this.contentCategory.totalTime, '|')}`;
  }

  setTime(timeInSeconds: number, additional?: string) {
    if (!timeInSeconds) return '';

    return ` ${additional || ''} ${moment.utc(timeInSeconds * 1000).format('HH:mm:ss')}`;
  }

  permissionMaterial(material: Record<string, any>) {
    if (
      this.idBook === LIST_BOOK_ID.REDACTION
      && this.can(LIST_PERMISSION.ALL_MATERIALS_REDACTION)
    ) return true;

    return !this.availablePermissionBook
      ? true
      : hasPermissionMaterials(material);
  }

  @Watch('count')
  @Watch('checkedAllMaterial')
  setCheckAll() {
    this.newMaterials = this.newMaterials.map((material: InterfaceMaterials) => {
      const newMaterial = {
        ...material,
        checked: this.checkedAllMaterial,
      };

      this.updateFileChecked(newMaterial);

      return newMaterial;
    });

    if (this.checkedAllMaterial) {
      this.setMaterialsDownload();
    } else {
      this.downloadFile = [];
    }
  }

  mappersMaterials() {
    this.newMaterials = this.materials?.files.map((material: InterfaceMaterials) => ({ ...material, checked: false }));
  }

  setCheckMaterial(materialItemSlugfix: string) {
    this.newMaterials = this.newMaterials.map((item: InterfaceMaterials) => {
      const material = item;

      if (material.slugfix === materialItemSlugfix) {
        material.checked = !material.checked;
        this.updateFileChecked(material);
      }

      return material;
    });

    this.setMaterialsDownload();
  }

  setMaterialsDownload() {
    const listFilesDownload = this.newMaterials.filter((material: InterfaceMaterials) => material.checked);

    this.downloadFile = uniqBy(listFilesDownload, 'slugfix');
  }

  isChecked(id: number, param: any) {
    const result = this.allChecked[param].some((checkedItem: Record<string, any>) => {
      if (checkedItem?.idobject === id) {
        return checkedItem.checked;
      }
      return false;
    });
    return result;
  }

  emitUpdateAllChecked(id: any) {
    this.$emit('checkedMaterials', id);
  }

  updateFileChecked(material: InterfaceMaterials) {
    this.$emit('materialChecked', material);
  }

  verifyNameTheory(titleMaterials: string) {
    return titleMaterials ? titleMaterials.includes(nameTheory) : false;
  }

  emitClickOpenContent(data: Record<string, any>) {
    this.$emit('click-open-content', data);
  }

  showCategoryContext(files: Array<Record<string, any>>) {
    if (!this.isBook) return files.length;

    const listCategoryContextStore = this.$store.getters.categoryFilterByContext.find((category: MaterialCategoryStore) => category.context === TYPE);

    const checkedAll = listCategoryContextStore.categories.every((context: Record<string, any>) => context.checked);

    if (checkedAll) return true;

    const currentCategoryId = files[0].IDMaterialCategory;

    const categoryContext = listCategoryContextStore.categories.find((context: Record<string, any>) => context.idCategory === currentCategoryId);

    return categoryContext.checked;
  }
}
