import AppClient from '../AppClient';

export default class PdfService {
  private basePath = process.env.VUE_APP_EXP;

  private Client = new AppClient({ url: this.basePath });

  async getPdf(slugfix: string, token: string) {
    const URI = `/app/ver/apostila/${slugfix}`;

    const response = await this.Client.getBuffer(URI, { token });

    return response?.data;
  }
}
