






































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import moment from 'moment';

import RegroupType from '@/components/RegroupType/index.vue';
import SubjectList from '@/components/Subject/SubjectList/index.vue';
import IconStatus from '@/components/IconStatus/index.vue';

import listAccordion from '@/mixins/listAccordion';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';

import { ContextCount } from '@/globalInterfaces/Book';

import { hasPermissionVideos } from '@/share/Util/Permissions/videos';

const TYPE = 'videos';
const ROUTE_BOOK = 'BooksView';

interface InterfaceAllChecked {
  videos: [];
  materials: [];
  exercices: [];
}

interface MaterialCategoryStore {
  categories: Array<Category>;
  context: string;
}

interface Category {
  idCategory: number;
  checked: boolean;
}

@Component({
  mixins: [listAccordion],
  components: {
    RegroupType,
    SubjectList,
    IconStatus,
  },
})
export default class VideosTab extends Vue {
  @Prop({ default: true }) isPermission!: boolean;
  @Prop({ default: false }) showLesson!: boolean;
  @Prop({ default: false }) isPlanner!: boolean;
  @Prop({ default: null }) idBook!: null | number;
  @Prop() allChecked!: InterfaceAllChecked;
  @Prop() topic!: Record<string, any>;
  @Prop() categoryInformation!: {[key: number]: ContextCount};
  @Prop() checked!: Function;
  @Prop() setData!: Function;

  get isBook() {
    return this.$route.name === ROUTE_BOOK;
  }

  get videos() {
    if (this.isPlanner) return this.getFilesType(this.topic.videos, 'videos');

    return this.isPlanFree ? this.shuffleFiles(this.topic.videos) : this.topic.videos;
  }

  get plan() {
    return this.$store.getters.profile?.plan;
  }

  get isPlanFree() {
    return this.plan?.planID === LIST_PLAN_ID.FREE;
  }

  permissionExercise(video: Record<string, any>) {
    return hasPermissionVideos(video);
  }

  setInfoVideos(category: number) {
    const contentCategory = this.categoryInformation[category];

    if (contentCategory) {
      if (!contentCategory.totalCount) return this.setTime(contentCategory.totalTime);

      return `${contentCategory.totalCount} ${contentCategory.totalCount > 1 ? 'aulas' : 'aula'} ${this.setTime(contentCategory.totalTime, '|')}`;
    }

    return '';
  }

  setTime(timeInSeconds: number, adittional?: string) {
    if (!timeInSeconds) return '';

    return `${adittional || ''} ${moment.utc(timeInSeconds * 1000).format('HH:mm:ss')}`;
  }

  isChecked(id: number) {
    const result = this.allChecked.videos.some((checkedItem: Record<string, any>) => {
      if (checkedItem?.idobject === id) {
        return checkedItem.checked;

      }
      return false;
    });

    return result;
  }

  getProgressBar(id: number) {
    let result = 0;

    if (this.allChecked.videos && this.allChecked.videos.length > 0) {
      this.allChecked.videos.forEach((checkedItem: Record<string, any>) => {
        if (checkedItem?.idobject === id) {
          result = checkedItem.percent;
        }
      });
    }

    return result;
  }

  getFilesType(files: Array<Record<string, any>>, type: string) {
    let listFileType: Array<Record<string, any>>;
    const videos = this.$store.getters.plannerAccordionFiles?.videos;
    const archives = this.$store.getters.plannerAccordionFiles?.archives;

    if (type === 'videos' && files.length && videos.length) {
      listFileType = files.map((video: Record<string, any>) => video.idVideo);

      const listFiles: Array<Record<string, any>> = [];

      listFileType.forEach((idVideo: Record<string, any>) => {
        const found = videos.find((videoItem: Record<string, any>) => videoItem.idVideo === idVideo);
        if (found) listFiles.push(found);
      });

      return listFiles;
    }

    if (type === 'archives' && files.length && archives.length) {
      listFileType = files.map((archive: Record<string, any>) => archive.idArchive);

      const listFiles: Array<Record<string, any>> = [];

      listFileType.forEach((idArchive: Record<string, any>) => {
        const found = archives.find((archiveItem: Record<string, any>) => archiveItem.idArchive === idArchive);
        if (found) listFiles.push(found);
      });

      return listFiles;
    }

    return [];
  }

  shuffleFiles(files: []) {
    const shuffleNewFiles = files;
    for (let indexLoop = shuffleNewFiles.length - 1; indexLoop > 0; indexLoop -= 1) {
      const indexRandom = Math.floor(Math.random() * (indexLoop + 1));

      [shuffleNewFiles[indexLoop], shuffleNewFiles[indexRandom]] = [shuffleNewFiles[indexRandom], shuffleNewFiles[indexLoop]];
    }

    return shuffleNewFiles;
  }

  emitClickOpenContent(data: Record<string, any>) {
    this.$emit('click-open-content', data);
  }

  setTotalHours(files: Array<Record<string, any>>) {
    const totalTimes = files.reduce((acc: number, file: Record<string, any>) => acc + file ?.time, 0);

    if (!totalTimes) return '';

    const totalTime = moment.utc(totalTimes * 1000).format('HH:mm:ss');
    const totalFiles = files.length;

    if (totalFiles > 1) {
      return `${totalFiles} aulas | ${totalTime}`;
    }

    return `${totalFiles} aula | ${totalTime}`;
  }

  showCategoryContext(files: Array<Record<string, any>>) {
    if (!this.isBook) return files.length;

    const listCategoryContextStore = this.$store.getters.categoryFilterByContext.find((category: MaterialCategoryStore) => category.context === TYPE);

    const checkedAll = listCategoryContextStore.categories.every((context: Record<string, any>) => context.checked);

    if (checkedAll) return true;

    const currentCategoryId = files[0].IDMaterialCategory;

    const categoryContext = listCategoryContextStore.categories.find((context: Record<string, any>) => context.idCategory === currentCategoryId);

    return categoryContext.checked;
  }

  checkPermissionFree(idVideo: number) {
    // if (!this.isPlanFree) return this.isPermission;

    // const { is_credits_video } = this.$store.getters.creditData;

    // if (is_credits_video) return true;

    // const { idsVideoWatched } = this.$store.getters;

    // if (!idsVideoWatched.length) return false;

    // const videoAlreadySeenThisMonth = idsVideoWatched.includes(idVideo);

    // return videoAlreadySeenThisMonth;

    return this.isPermission;
  }
}
